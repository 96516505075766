// extracted by mini-css-extract-plugin
export var button = "style-module--button--a3899";
export var cardImage = "style-module--card-image--e3746";
export var cardImageImg = "style-module--card-image-img--603c9";
export var cardRow = "style-module--card-row--36802";
export var cardValuesHeader = "style-module--card-values-header--94721";
export var cardValuesHeaderMobile = "style-module--card-values-header-mobile--d4870";
export var cardValuesHeaderTablet = "style-module--card-values-header-tablet--2a28b";
export var description = "style-module--description--53975";
export var dropdown = "style-module--dropdown--a2dd4";
export var heading = "style-module--heading--0115f";
export var innerProductComponent = "style-module--inner-product-component--a1689";
export var listHeader = "style-module--list-header--9486c";
export var listHeaderMobile = "style-module--list-header-mobile--6ce9b";
export var productComponent = "style-module--product-component--f1e2b";
export var rightContainer = "style-module--right-container--5a6ac";