import { CircularProgress, useMediaQuery } from "@mui/material"
// import CardValuesHeader from "components/content/card-values-header"
import Markdown from "components/markdown"
import { Typography } from "components/typography"
import Product from "models/Product"
import React, { useEffect, useState } from "react"
import { TransactionAccountDisclaimer } from "../../../components/content/content-disclaimer"
import ProductComponent from "../../../components/content/transaction-accounts/product-component"
import Layout from "../../../components/layout"
import { ListHeaderProvider } from "components/content/list-header"
import * as styles from "./style.module.scss"
import ReturnLinkChain from "components/content/return-link-chain"
import { CardAds as Ads, SavingsAccountAds } from "components/content/ads"
import Pagination from "components/pagination"
import TransactionAccountProvider from "models/provider-models/TransactionAccountProvider"
import AccountFeature from "models/feature-models/AccountFeature"
import AccountProduct from "models/product-models/AccountProduct"
import Sort from "components/content/sort"
import FilterMenu from "components/content/filter-menu"
import * as ProductValuesHeader from "components/content/product-values-header"
import Faq from "components/content/faq"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProviderTemplate = (props: any) => {
  const { categoryJson, dateUpdated, ads, qa }: { categoryJson: TransactionAccountProvider | AccountFeature; dateUpdated: string; ads: any; qa?: any } = JSON.parse(props.pageContext.p)
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")
  const [filter, setFilter] = useState<string[]>([])
  const [sort, setOnlySort] = useState("Interest Rate")
  const [tempFilter, setTempFilter] = useState<string[]>([])
  const [linkFirst, setLinkFirst] = useState(true)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const majors = ["00100", "00200", "00300", "00400"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)
  const [page, setPage] = useState(0)
  const [offersPerPage, setOffersPerPage] = useState(10)

  const [isLoading, setLoading] = useState(false)

  const updateFilter = (v: string[]) => {
    setPage(0)
    setFilter(v)
  }

  const updateOnlySort = (v: string) => {
    setPage(0)
    setOnlySort(v)
  }

  const updateTempFilter = (v: string[]) => {
    setPage(0)
    setTempFilter(v)
  }

  function makeProductComponent(product: Product, key: number, isLast?: boolean, offerFilter?: string) {
    return (
      <div key={product.id.toString() + "-" + key.toString()} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }} className={styles.productComponent}>
        <div className={styles.innerProductComponent}>
          <ProductComponent product={product as AccountProduct} learnMore offerFilter={offerFilter} isTablet={isTablet} />
        </div>
      </div>
    )
  }

  function isProductComponent(component: JSX.Element) {
    return component.props.className && component.props.className === styles.productComponent
  }

  function makeCategoryHeader(headerName: string) {
    return (
      // <div key={headerName} className={!isWide || isTablet ? styles.listHeaderMobile : styles.listHeader}>
      //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{headerName}</div>
      // </div>
      <ListHeaderProvider key={headerName} isMobile={!isWide || isTablet}>
        {headerName}
      </ListHeaderProvider>
    )
  }

  function setSort(name: string) {
    setLoading(true)
    updateOnlySort(name)
  }

  let category: TransactionAccountProvider | AccountFeature | null = null
  // Initialise Provider, Reward or Feature (TODO: identifying class might need to be more robust)
  if ("big4" in categoryJson) {
    category = TransactionAccountProvider.Initialise(categoryJson) as TransactionAccountProvider
  } else {
    category = AccountFeature.Initialise(categoryJson)
  }

  // sort and filter
  let productList: JSX.Element[] = category.getProductList(makeProductComponent, makeCategoryHeader)

  // Filter out products
  const productGroups: JSX.Element[][] = []
  productList.forEach(productComponent => {
    if (productComponent) {
      if (isProductComponent(productComponent)) {
        // less hacky
        // if (typeof productComponent.props.children.props.children !== "string") {
        const propsProduct: AccountProduct = productComponent.props.children.props.children.props.product
        let pricingFilter: boolean[] = []
        let rewardsFilter: boolean[] = []
        let offerFilter: boolean[] = []
        let featureFilter: boolean[] = []
        let providerFilter: boolean[] = []
        let availabilityFilter: boolean[] = []
        filter.forEach((filterType: string) => {
          switch (filterType) {
            // TODO: add products to filters
            case "Major Banks":
              if (!majors.includes(propsProduct.providerId)) {
                providerFilter.push(false)
              } else {
                providerFilter.push(true)
              }
              break
            case "Challengers":
              if (majors.includes(propsProduct.providerId)) {
                providerFilter.push(false)
              } else {
                providerFilter.push(true)
              }
              break
            case "For Sale":
              if (propsProduct.forSale !== "Y" && propsProduct.forSale !== "EXCLUDED") {
                availabilityFilter.push(false)
              } else {
                availabilityFilter.push(true)
              }
              break
            case "Not For Sale":
              if (propsProduct.forSale === "Y" || propsProduct.forSale === "EXCLUDED") {
                availabilityFilter.push(false)
              } else {
                availabilityFilter.push(true)
              }
              break
            case "No Montly Fees":
              pricingFilter.push(propsProduct.getTotalMonthlyFee() === 0)
              break
            case "No FX Fees":
              pricingFilter.push(!propsProduct.fxFee || propsProduct.fxFee === 0)
              break
          }
        })
        if ((pricingFilter.includes(true) || pricingFilter.length === 0) && (rewardsFilter.includes(true) || rewardsFilter.length === 0) && (offerFilter.includes(true) || offerFilter.length === 0) && (featureFilter.includes(true) || featureFilter.length === 0) && (providerFilter.includes(true) || providerFilter.length === 0) && (availabilityFilter.includes(true) || availabilityFilter.length === 0)) {
          productGroups[productGroups.length - 1].push(productComponent)
        }
      } else {
        if (sort === "Product Category") {
          productGroups.push([productComponent])
        } else {
          productGroups.push([])
        }
      }
    }
  })

  const sortByInterest = (a: Product, b: Product) => {
    const interestRateA = AccountProduct.Initialise(a).getMinInterestRate()
    const interestRateB = AccountProduct.Initialise(b).getMinInterestRate()
    return (interestRateB ? interestRateB.interestRate : 0) - (interestRateA ? interestRateA.interestRate : 0)
  }

  const sortByAccountFee = (a: Product, b: Product) => {
    const accountFeeA = AccountProduct.Initialise(a).getTotalMonthlyFee()
    const accountFeeB = AccountProduct.Initialise(b).getTotalMonthlyFee()
    return accountFeeA === accountFeeB ? sortByInterest(a, b) : accountFeeA - accountFeeB
  }

  let sortMethod: ((a: Product, b: Product) => number) | undefined = undefined
  switch (sort) {
    case "Interest Rate":
      sortMethod = sortByInterest
      break
    case "Account Fees":
      sortMethod = sortByAccountFee
      break
  }
  if (linkFirst) {
    const subsortMethod = sortMethod
    sortMethod = (a, b) => {
      const productA = AccountProduct.Initialise(a)
      const productB = AccountProduct.Initialise(b)
      const hasOfferLinkA = Object.values(productA.offers || {}).some(offer => offer.active === "Y" && offer.landingPageUrl !== null)
      const hasOfferLinkB = Object.values(productB.offers || {}).some(offer => offer.active === "Y" && offer.landingPageUrl !== null)
      if (hasOfferLinkA && !hasOfferLinkB) {
        return -1
      } else if (hasOfferLinkB && !hasOfferLinkA) {
        return 1
      } else {
        if (subsortMethod !== undefined) {
          return subsortMethod(a, b)
        } else {
          return productB.getMaxTotalInterestRate() - productB.getMaxTotalInterestRate()
        }
      }
    }
  }

  productList = []
  productGroups.forEach(group => {
    if (group.length > 1 || (sort !== "Product Category" && group.length > 0)) {
      productList = productList.concat(group)
    }
  })

  // Add "No Products" component if empty
  if (productList.length === 0) {
    productList.push(<div style={{ textAlign: "center", fontSize: "24px", fontFamily: "Arboria-Book" }}>No Products</div>)
  } else {
    // Sort products
    if (sort !== "Product Category") {
      productList.sort((a, b) => {
        const productA: AccountProduct = a.props.children.props.children.props.product
        const productB: AccountProduct = b.props.children.props.children.props.product
        return sortMethod ? sortMethod(productA, productB) : sortByInterest(productA, productB)
      })
    }
  }

  // sortList
  // TODO: add sort options
  const sortList: string[] = ["Interest Rate", "Account Fees"]

  // filterList
  const filterList: { [type: string]: string[] } = {
    // TODO: add filter options
    pricing: ["No Monthly Fees", "No FX Fees"],
    provider: ["Major Banks", "Challengers"],
    // availability: ["For Sale", "Not For Sale"],
  }

  // filterAmounts
  const filterAmounts: { [id: string]: number } = {
    // TODO: add filter amounts
    "No Monthly Fees": 0,
    "No FX Fees": 0,
    "Major Banks": 0,
    Challengers: 0,
    "For Sale": 0,
    "Not For Sale": 0,
  }

  // filterAmounts
  let totalAmount: number = 0
  productList.forEach((productComponent: JSX.Element) => {
    if (productComponent.props.children !== "No Products") {
      // if (typeof productComponent.props.children.props.children !== "string") {
      if (isProductComponent(productComponent)) {
        // less hacky
        totalAmount += 1
        const product: AccountProduct = productComponent.props.children.props.children.props.product

        // filterAmounts
        // TODO: count filter amounts
        if (majors.includes(product.providerId)) {
          filterAmounts["Major Banks"] += 1
        } else {
          filterAmounts["Challengers"] += 1
        }
        if (product.forSale === "Y" || product.forSale === "EXCLUDED") {
          filterAmounts["For Sale"] += 1
        } else {
          filterAmounts["Not For Sale"] += 1
        }
        if (product.getTotalMonthlyFee() === 0) {
          filterAmounts["No Monthly Fees"] += 1
        }
        if (!product.fxFee || product.fxFee === 0) {
          filterAmounts["No FX Fees"] += 1
        }
      }
    }
  })

  // Pagination
  const paginatedProductLists: JSX.Element[][] = []
  let paginatedProductList: JSX.Element[] = []
  const counts: number[] = []
  let count = 0

  for (let i = 0; i < productList.length; i++) {
    paginatedProductList.push(productList[i])
    if (isProductComponent(productList[i])) {
      count += 1
    }
    if (count === 10) {
      paginatedProductLists.push(paginatedProductList)
      counts.push(count)
      paginatedProductList = []
      count = 0
    }
  }
  if (paginatedProductList.length > 0) {
    paginatedProductLists.push(paginatedProductList)
  }
  counts.push(count)

  if (page >= paginatedProductLists.length) {
    setPage(0)
  }

  productList = paginatedProductLists[page]

  const paginationRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (productList.length > 0) {
      setLoading(false)
    }
  }, [isLoading])

  return (
    <Layout title={category.getTitle()} description={category.getAutoDescription()} urlSlug={`${category.urlSlug ? category.urlSlug : category.nameFormatted}-bank-accounts`} dateUpdated={dateUpdated} includeMozoDisclaimer>
      {isLoading ? (
        <div style={{ position: "absolute", marginLeft: "50%", marginRight: "50%", marginTop: "30%", zIndex: 99 }}>
          <CircularProgress thickness={6} />
        </div>
      ) : null}
      <div className="page-container" style={{ display: "flex", alignItems: "stretch" }}>
        <div className="content-provider-container" style={{ maxWidth: isTablet ? (isWide ? "648px" : "395px") : "960px" }}>
          <ReturnLinkChain linkChain={[{ name: "Bank Accounts", to: "/bank-accounts/" }]} currentLocationItem={{ name: category.heading }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} />
          <div className={styles.heading}>
            <Typography.H1>Compare {category.heading} Bank Accounts</Typography.H1>
          </div>
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {category.getAutoDescription()}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {ads ? ads.product.id.includes("SV") ? <SavingsAccountAds ads={ads} /> : null : null}
          <div ref={paginationRef} />
          <div className={styles.cardValuesHeader}>
            <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} mobile={true} productValuesHeaderComponent={<ProductValuesHeader.TransactionAccountValuesHeader setSort={setSort} sort={sort} />} />
          </div>
          <div style={{ display: "flex", opacity: isLoading ? "0.4" : "1" }}>
            <div style={{ maxWidth: "648px", width: "100%" }}>
              {sort !== "Product Category" && (!isTablet || !isWide) ? (
                // <div key={"sort"} className={!isWide || isTablet ? styles.listHeaderMobile : styles.listHeader}>
                //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{sort}</div>
                // </div>
                <ListHeaderProvider isMobile={!isWide || isTablet}>{sort}</ListHeaderProvider>
              ) : null}
              {isTablet && isWide ? (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "337.5px", marginRight: "50px" }}>
                    <div className={styles.cardValuesHeaderTablet}>
                      <div style={{ marginLeft: "19px", marginRight: "19px", display: "flex" }}>
                        <div style={{ height: "100%", width: "100%" }}>
                          <ProductValuesHeader.TransactionAccountValuesHeader forFilter={true} setSort={setSort} sort={sort} />
                        </div>
                      </div>
                    </div>
                    {sort !== "Product Category" ? (
                      // <div key={"sort"} className={styles.listHeaderMobile}>
                      //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{sort}</div>
                      // </div>
                      <ListHeaderProvider isMobile>{sort}</ListHeaderProvider>
                    ) : null}
                    {productList}
                    <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} counts={counts} scrollRef={paginationRef} />
                  </div>
                  <div style={{ marginTop: "70px" }}>
                    <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>Total Offers: {totalAmount}</div>
                    <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} />
                  </div>
                </div>
              ) : (
                <div>
                  {productList}
                  <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} counts={counts} scrollRef={paginationRef} />
                </div>
              )}
            </div>
            {!isTablet ? (
              <div style={{ marginTop: "10px", marginLeft: "43px" }}>
                <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>Total Offers: {totalAmount}</div>
                <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} />
              </div>
            ) : null}
          </div>
          <div style={{ maxWidth: "648px" }}>
            {qa ? (
              <div style={{ marginTop: "60px" }}>
                {/* <div className={styles.listHeader}>
                    <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{"FAQs"}</div>
                  </div> */}
                <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
                {/* <div>
                  <Markdown md={qa.qa.qa} isArticle={true} />
                </div> */}
                <Faq faqContent={qa.qa.qa} />
              </div>
            ) : null}
            <TransactionAccountDisclaimer isDisclaimerOpen isProvider includeBestDisclaimer={category.name === "Best"} dateString={dateUpdated} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProviderTemplate
